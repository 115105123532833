import React from 'react'
import {
  Loading, notify, getGlobal, Collapsible, Modal,
  apiPost, Snip, helpers, Spinner, getSnip
} from 'launchpad'
import { NavLink } from 'react-router-dom'
import Page from '_shared/layout-page/Page';
import styled from 'styled-components';
import Slideshow from '_shared/partial-slideshow/Slideshow'
import Select from 'react-select'
import CheckMark from 'launchpad/admin/widgets/CheckMark'
import { refreshRoutes } from 'config/routes'
import CheckBox from '@material-ui/core/Checkbox';
import {
  loadProduct, Product, isFas, ProductImage, BreadCrumbs, SizeInfo, getSizeLabel, ShippingInfo
} from 'product_tools';
import { RelatedProducts } from 'widgets/ProductGallery'
import ProductEditor from 'templates/ProductEditor'
import { Qty } from 'widgets'
import { onEnterOrSpaceDownClick } from '../../utils';
import AdvMuiModal from '../widgets/AdvMuiModal';
import * as GTMEvents from '../utils/GTMEvents';

const blue = `rgb(38, 63, 143)`

export const Sect = styled.section`
  display: none
  .btnCenter {
    text-align: center;
  }
  .popupHead {
    text-align: center;
    font-weight: 500;
    font-size: 24px;
  }
  .popupDesc {
    text-align: center;
    color: black;
  }
  button {
    color: white;
    background: rgb(38, 63, 143);
    padding: 10px 15px;
    border-radius: 3px;
    margin: 5px 10px
  }
`
const ProductDetail = styled.div`
  padding-top: 30px;
  padding-bottom: 80px;
  align-items: center;
  position: relative;
  .title {
    margin-top: 0;
  }
  .bread-crumbs {
    margin-left: 15px;
    margin-bottom: 20px;
  }
  .what-comes {
    float: right;
    display: inline;
    cursor: pointer;
  }
  .images {
    padding-top: 30px;
    display:flex;
    align-items: center;
    .thumbnails {
      display:flex;
      flex-direction: column;
      cursor: pointer;
      .image {
        margin: 5px;
      }
      .thumbnail {
        margin-left: -15px;
      }
    }
    .image {
      position:relative;
      padding-bottom: 80%;
      display:flex;
      height: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      span {
        position:absolute;
        top:calc(50% - 12px)
      }
    }
  }

  .shipping {
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 10px;
    ${'' /* .table-container {
      padding: 0;
    } */}

    table {
      width: 100%;
      border-left: 1px solid #ddd;
      border-right: 1px solid #ddd;
      border-top: 1px solid #ddd;
      th {
        background: #eee;
      }
      td, th {
        text-align: center;
        border-bottom: 1px solid #ddd;
        padding: 5px 10px;
        &:not(:first-child) {
          border-left: 1px solid #ddd;
        }
      }
      tr.active {
        background: rgb(255, 255, 200);
      }
    }
  }

  h5 {
    margin: 0;
    padding: 0;
  }

  ul {
    padding-left: 20px;
    padding-top: 10px;
  }

  .price {
    padding: 10px 0;
  }

  .description {
    div {
      border-top: 1px solid #ddd;
      padding-top: 15px;
    }
    margin-top: 25px;
    margin-bottom: 10px;
    a {
      cursor: pointer;
    }
  }

  .option {
    cursor: pointer;
    position: relative;
    bottom: 0px;
    transition: bottom .3s;
  }

  .color-selector {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    padding-bottom: 5px;
    margin-bottom: 10px;
    border-bottom: 1px solid #ddd;

    .option {
      width: 60px;
      border-radius: 5%;
      margin: 5px;
      border: 2px solid #ddd;
      position: relative;
      padding: 2px;
      .fa-fast-backward {
        display: none;
        position: absolute;
        text-align: center;
        top: 4px;
        left: 2px;
      }
      .tool-tip {
        position: absolute;
        top: 110%;
        z-index: 3;
        width: 200%;
        left: -50%;
        text-align: center;
        background: white;
        border-radius: 5%;
        box-shadow: 0 0 1px 3px rgba(0,0,0,.1);
        opacity: 0;
        transition: opacity .2s;
        pointer-events: none;
      }
      &:hover, &:focus {
        .tool-tip {
          opacity: 1;
        }
        .fa-fast-backward {
          display: block;
        }
      }
      .cross-bar {
        border-top: 3px dashed #ddd;
        position: absolute;
        top: 50%;
        left: -20%;
        width: 140%;
        transform: rotate(-45deg);
        opacity: 0;
        transition: opacity .3s;
      }
      .inner {
        padding-bottom: 100%;
        border-radius: 5%;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
      &.disabled {
        .cross-bar {
          opacity: 1;
        }
        .inner {
          opacity: .5;
        }
        border: 3px dashed #ddd;
      }
      &.active {
        border: 2px solid #eee;
        box-shadow: 0 0 0 3px ${blue};
      }

    }
  }

  .sizes {
    padding-top: 30px;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #ddd;
    .size-types {
      margin: 10px 0;
      button {
        padding: 1px 4px 1px 4px;
        background: #ddd;
        font-size: 1em;
        border: 1px solid #ddd;
        &.active {
          background: ${blue};
          color: white;
        }
      }
    }
    .custom-params {
      display: flex;
      > * {
        flex: 1;
        flex-shrink: 1;
        margin-right: 20px;
      }
      input {
        padding: 5px;
        width: 100%;
      }
      .box-field {
        text-align: center;
        .check-box {
          > span {
            display: inline-block;
            margin: 0;
            padding: 0;
          }
        }
      }
    }
  }

  .size-selector {
    padding: 5px 0;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;

    > div {
      flex: 1;
    }
    ${'' /* span {
      margin: 15px;
    } */}
    .size-option {
      margin: 0;
      padding: 0 10px;
      opacity: .3;
      display: flex;
      &.active {
        opacity: 1;
      }
      .size-stock-label {
        margin-left: auto;

      }
    }
    .size_option {
      border-radius: 5%;
      margin: 3px;
      flex: none;
      text-align:center;
      border: 2px solid #ddd;
      position: relative;
      padding: 2px;
      &.disabled {
        .cross-bar {
          opacity: 1;
        }
        border: 3px dashed #ddd;
      }
      &.small {
        width: 65px;
      }
      &.big {
        width: 110px;
      }
      &.hide{
        display:none;
      }
      .inner {
        width: fit-content;
        cursor: pointer;
        display:inline-block;
      }
      &.active {
        border: 2px solid #eee;
        box-shadow: 0 0 0 3px ${blue};
      }
    }
  }

  .your-order {
    h5 {
      margin: 15px 0;
    }
    p {
      margin: 15px 0;
      .animated-checkmark {
        display: inline-block;
        width: 30px;
      }
    }
  }

  .cart-btn {
    display: block;
    width: 100%;
    margin-top: 0px;
    font-size: 1.2em;
    padding: 15px;
    opacity: .4;
    pointer-events: none;
    &.valid {
      opacity: 1;
      pointer-events: auto;
    }

    &.go-to-cart {
      color: #fff;
      margin-top: 10px;
    }
  }

  .price-row {
    display: flex;
    align-items: center;
    label {
      margin-right: 10px;
    }
  }

  .features {
    border-top: 1px solid #ddd;
    padding: 0;
    margin-bottom: 0
  }

  .related-products {
    margin-top: 0;
    padding-top: 10px;
    border-top: 1px solid #ddd;
    width: 100%;
    .product-image {
      background-position: top;
      height: auto;
      margin: 20px 0px;
    }
    &:last-of-type {
      margin-bottom: 40px;
    }
  }

  .no-stock {
    h5 {
      margin-bottom: 10px;
    }
  }

  .shipping {
    margin-top: 20px;
  }

  .mobile {
    display: none;
  }
  .desktop {
    display: block;
  }
  .desktopview{
      display: flex;
  }

  .mobile-details {
    margin-top: 40px;
    h3 {
      padding: 10px 30px;
      margin: 0;
      color: white;
      font-size: 18px;
      background: ${blue};
      margin-left: -30px;
      margin-right: -30px;
      border-bottom: 1px solid white;
    }
    .description {
      margin-bottom: 10px;
      margin-top: 10px;
      padding-top: 10px;
    }
    .features {
      border: 0;
      padding-top: 0;
    }
  }

  @media (max-width: 812px) {
    padding-top: 20px;
    .images {
      padding-top: 0;
      .thumbnails {
        display: flex;
        flex-direction: row;
        .thumbnail {
          margin: 3px;
        }
      }
    }
    .features {
      margin-top: 30px;
      padding: 30px 0 0px;
    }
    .related-products {
      margin-top: 30px;
      .product-image {
        background-position: top;
        height: auto;
        margin: 20px 0px;
      }
    }

    .mobile {
      display: block;
    }
    .desktop {
      display: none;
    }
    .desktopview{
      display: none;
    }
    .bread-crumbs {
      margin-bottom: 0px;
    }
    .title {
      margin: 0;

    }
  }
`


export default class ProductPage extends React.Component {
  constructor(props) {
    super(props)
    this.goToCartRef = React.createRef()
    this.state = {
      // start with empty product object so methods/props exist
      product: new Product({}, []),
      skus: [],
      skusLoaded: false,
      minPrice: 0,
      maxPrice: 0,
      sizeType: 'standard',

      color: '',
      length: '',
      width: '',
      qty: 1,
      shellOnly: false,
      foamCore: false,

      colors: [],
      widths: [],
      lengths: [],
      sizes: [],

      valid: {
        colors: [],
        widths: [],
        lengths: []
      },

      customAvailable: false,
      customPrices: {},

      selectedSkus: [],
      selectedItem: {},
      validItem: false,
      showGalleryModal: false,
      showShellOnlyModal: false,
    }
  }

  componentDidMount() {
    this.loadProduct()
    setTimeout(() => {
      const money = (n) => {
        if (n) {
          //console.log("nnnnnnnnn", n);
          let t = (n * 1000).toFixed();
          let st = ((t % 100)) / 10;
          //console.log("sttttt", st);
          if (t % 10 == 5) {
            t = t / 1000 + 0.01;
          } else {
            t = t / 1000;
          }
          //console.log("ttttttt", t);
          return t.toFixed(2);
        }
      };
    }, 2000);
    if (screen.width > 768) {
      const handleMousemove = (e) => {
        e.preventDefault()
        //console.log("Test")
        var zoomer = e.target;
        let offsetX = 0;
        let offsetY = 0;
        //console.log("em", e);
        e.offsetX ? offsetX = e.offsetX : offsetX = e.touches[0].pageX
        e.offsetY ? offsetY = e.offsetY : offsetY = e.touches[0].pageY
        let x = offsetX / zoomer.offsetWidth * 100
        let y = offsetY / zoomer.offsetHeight * 100
        zoomer.style.backgroundPosition = x + '% ' + y + '%';
        zoomer.style.backgroundSize = 'auto';
      }

      const left = document.querySelectorAll(".slide-content");

      left.forEach(function (a) {
        a.addEventListener("mousemove", handleMousemove, false);
        a.addEventListener("mouseout", function (e) { let t = e.target; t.style.backgroundSize = "contain" }, false);
      })

      $(document).on('click', "#gallery-modal-id .thumbnails .thumbnail", function (e) {
        let t = e.target;
        var a = Array.from($(e.target).parent().children()).indexOf(t);
        $(".slide-container").css("left", a * -100 + "%")
      })
    }
  }

  componentWillUnmount() {
    this.state.product.unregisterListener(this)
  }

  // TODO: Would like to move the next several functions into product_tools, this file is bloated
  loadProduct = () => {
    loadProduct(this.props.id).then(p => {
      p.registerListener(this)
      this.setState({ product: p })
      GTMEvents.viewItem(p);
      p.loadData().then(() => {
        let skus = p.getSkus()
        if (!skus.length) skus = p.skus
        let colors = []
        let lengths = []
        let widths = []
        let sizes = []
        let customAvailable = false
        let customPrices = {}
        //let {colors, lengths, widths, sizes, customAvailable, customPrices} = this.state
        skus.forEach(sku => {
          if ((sku.length || sku.width) && sku.color && sku.visible && !sku.sku.toLowerCase().startsWith('c')) {
            if (!colors.includes(sku.color)) colors.push(sku.color)
            if (!lengths.includes(sku.length)) lengths.push(sku.length)
            if (!widths.includes(sku.width)) widths.push(sku.width)
            if (!sizes.includes(`${sku.length}x${sku.width}`)) sizes.push(`${sku.length}x${sku.width}`)
          }
          if (sku.visible && sku.sku.toLowerCase().startsWith('c')) {
            customAvailable = true
            customPrices[sku.color] = sku.price
          }
        })
        //console.log(customPrices)
        sizes = sizes.sort((a, b) => {
          const sa = this.unpackSize(a)
          const sb = this.unpackSize(b)
          if (sa.length == sb.length) return sa.width - sb.width
          return sa.length - sb.length
          //return (sa.length + sa.width) - (sb.length + sb.width)
        })
        this.setState({
          color: colors[0], colors, lengths, widths, sizes, skus, customAvailable,
          customPrices, valid: { colors, lengths, widths }, skusLoaded: true
        }, this.sanityCheck)

        if (sizes.length == 1) {
          this.setState({ size: sizes[0] })
        }

        this.checkQueryString();
      })
    }).catch(err => {
      console.log(err)
    })
  }

  // Parse Query String and try to get color from it
  checkQueryString = () => {
    try {
      const urlParams = new URLSearchParams(location.search);
      const colorUrlParam = urlParams.get('color')
      const sizeUrlParam = urlParams.get('size')

      if (this.state.valid.colors.includes(colorUrlParam)) {
        this.setState({ color: colorUrlParam })
      }

      // if (this.state.valid.)
      if (sizeUrlParam) {
        try {
          const [length, width] = sizeUrlParam.split('x');
          if (this.state.valid.widths.includes(Number(width)) && this.state.valid.lengths.includes(Number(length))) {
            this.setState({ length, width })
          }
        } catch (e) {
          // Not handling this intentionally
        }
      }

      this.sanityCheck();
    } catch {
      console.error('Error parsing query string');
    }
  }

  // Write color and size to the query string
  updateQueryString = () => {
    try {
      const urlParams = new URLSearchParams(location.search);
      if (urlParams.has('fasOnly')) {
        // If there's fasOnly parameter, patch it so that it'll become fasOnly=1
        urlParams.set('fasOnly', 1)
      }

      if (this.state.color) {
        urlParams.set('color', this.state.color)
      }
      if (this.state.width && this.state.length) {
        urlParams.set('size', [this.state.length, this.state.width].join('x'))
      }
      history.replaceState(null, null, `${location.pathname}?${urlParams.toString()}`)
    } catch {
      console.error('Error writing to query string')
    }
  }

  sanityCheck = (primary) => {
    // if clearing a value, we know the current state is valid
    // if(!this.state[primary] && this.state[primary] !== false) return

    const { product, color, length, width, minPrice, maxPrice, foamCore, customPrices } = this.state
    const skus = product.getSkus()
    let custom = (this.state.sizeType == 'custom')

    // set up new state object from current state
    let newState = { color, length, width, minPrice, maxPrice }

    if (custom && color && !customPrices[color] && customPrices[color] !== 0) {
      notify(`Sorry, custom sizing is not available in "${color}"`)
      newState.color = ''
      newState.selectedItem = {}
    }
    // restrict checked skus to skus that match the primary key
    const safeSkus = skus.filter(x => {
      if (primary == 'color') return x.color == color
      return (x.length == length && x.width == width)
    });

    if (!custom) {
      let sizeNotified = false
      let colorNotified = false

      // check if the fields that weren't just set are still valid
      Object.keys(newState).forEach(key => {
        const excluded = [primary, 'minPrice', 'maxPrice']
        const primarySelected = primary == 'color' ? color : (length && width)
        if (!excluded.includes(key) && this.state[key] && primarySelected) {
          if (primary == 'color' && !sizeNotified) {
            if (!safeSkus.find(x => x.length == length) || !safeSkus.find(x => x.width == width)) {
              newState.length = ''
              newState.width = ''
              newState.selectedItem = {}
              notify(`Sorry, the standard size ${getSizeLabel(length, width)} is not available in ${color}, please choose a different size`);
              sizeNotified = true;
            }
          } else if ((primary == 'length' || primary == 'width') && !colorNotified) {
            if (!safeSkus.find(x => x.color == color)) {
              newState.color = ''
              newState.selectedItem = {}
              notify(`Sorry, ${color} is not available in ${length}" x ${width}"`)
              colorNotified = true;
            }
          }



          // // if no matching skus are found, unset that field
          // if(!safeSkus.find(x => x[key] == this.state[key])){
          //   newState[key] = ''
          //   if(primary != 'sizeType') notify(`Sorry, that frame ${key} is unavailable in your selected ${primary}`)
          // }

        }
      })
    }



    // list of valid options based on current configuration
    let valid = {
      colors: [],
      lengths: [],
      widths: []
    }


    // get a final list of valid skus based on altered state, while building
    // the list of valid options
    newState.selectedSkus = skus.filter(s => {
      if (!s.visible) return false

      if (custom) {
        if (customPrices[s.color] || customPrices[s.color] === 0) valid.colors.push(s.color)
      } else {
        const { color, length, width } = newState
        if ((!width || s.width == width) && (!length || s.length == length) && !valid.colors.includes(s.color)) {
          valid.colors.push(s.color)
        }
        if ((!color || s.color == color) && (!length || s.length == length) && !valid.widths.includes(s.width)) {
          valid.widths.push(s.width)
        }
        if ((!color || s.color == color) && (!width || s.width == width) && !valid.lengths.includes(s.length)) {
          valid.lengths.push(s.length)
        }
      }

      return parseFloat(s.length) && (
        (!color || s.color == color)
        && (!length || s.length == length)
        && (!width || s.width == width)
      )
    })

    if (skus.length == 1) {
      newState.selectedSkus = skus
      newState.color = skus[0].color
      newState.length = skus[0].length
      newState.width = skus[0].width
    }

    if (valid.lengths.length == 1 && valid.widths.length == 1) {
      newState.length = valid.lengths[0]
      newState.width = valid.widths[0]
    }

    let t = skus.find(x => x.sku && x.color == color && x.length == length && x.width == width)

    let customSku = '';
    //console.log("ttttttttt", t);
    custom = false;
    if (!t || this.state.shellOnly || this.state.foamCore) {
      custom = true;
      customSku = custom ? Object.assign({}, skus.find(x => x.sku.toLowerCase().startsWith('c') && x.color == color), { length, width }) : ''
    }

    if (custom) {
      const { qty, customPrices, shellOnly, foamCore, product } = this.state
      const customPrice = product.getSkuPrice(customSku, { qty, l: length, w: width, shellOnly, foamCore })
      newState.minPrice = customPrice
      newState.maxPrice = customPrice
      //newState.selectedSkus = [customSku]
    } else {
      newState.minPrice = 0
      newState.maxPrice = 0

      newState.selectedSkus.forEach(s => {
        const price = this.state.product.getSkuPrice(s)
        if ((price && price < newState.minPrice) || newState.minPrice == 0) newState.minPrice = price
        if (price && price > newState.maxPrice) newState.maxPrice = price
      })
    }


    newState.valid = valid
    this.setState(newState, () => {
      const { selectedSkus, product, qty, length, width, color, shellOnly, foamCore } = this.state
      if (this.isValid()) {

        let submittedSkus = selectedSkus
        if (custom && (!selectedSkus.length || shellOnly || foamCore)) {
          submittedSkus = [customSku]
        }

        product.getCartItem(submittedSkus, qty, shellOnly, foamCore).then(selectedItem => {
          if (this.checkSelected(selectedItem)) {
            this.setState({ selectedItem, validItem: true });
          } else {
            this.setState({ validItem: false })
          }
        })
      } else {
        this.setState({ selectedItem: {}, validItem: false })
      }
    })
  }

  sanitizeLW = (options) => {
    if (this.state.sizeType != 'custom') return true
    options = options || {}
    const l = parseFloat(this.state.length) || 0
    const w = parseFloat(this.state.width) || 0
    const min = 4
    const max = 36
    const rounded = (n) => Math.ceil(n * 4) / 4
    const validated = (n) => Math.max(min, Math.min(rounded(n), max))
    const notifyWrong = (prop) => notify(`Custom ${prop} must be in increments of .25" between ${min}" and ${max}"`)
    const validLength = validated(l)
    const validWidth = validated(w)
    if (validLength != l) {
      if (!options.skipNotify) {
        notifyWrong('length')
        this.setOption({ length: validLength })
      }
      return false
    }
    if (validWidth != w) {
      if (!options.skipNotify) {
        notifyWrong('width')
        this.setOption({ width: validWidth })
      }
      return false
    }
    return true
  }

  checkSelected = (selectedItem) => {
    const { qty } = this.state
    if (!selectedItem) return false
    if (!selectedItem.sku && (selectedItem.fasSku && selectedItem.fasInventory < parseFloat(qty))) {
      notify(`Sorry, we only have ${selectedItem.fasInventory} of that item in stock`)
      this.setOption({ qty: selectedItem.fasInventory })
      return false
    }
    return true
  }

  isValid = () => {
    if (this.state.product.getSkus().length == 1) return true
    let valid = true;
    (['color', 'length']).forEach(s => {
      if (!this.state[s] && this.state[s] !== 0) {
        valid = false
      }
    })
    return this.sanitizeLW({ skipNotify: true }) && valid
  }

  isFastShip = () => {
    const { selectedItem, qty, sizeType } = this.state
    return sizeType != 'custom' && selectedItem && selectedItem.fasSku && selectedItem.fasInventory >= qty
  }

  submit = async () => {
    this.setState({ submitting: true })
    const { selectedSkus, product, qty, color, minPrice} = this.state
    if (this.isValid()) {
      const cart = getGlobal('activeCart')
      let selectedItem = {...this.state.selectedItem};
      selectedItem.url = window.location.pathname + window.location.search;
      selectedItem.category = product.category._id;
      selectedItem.item_id = product._id;
      selectedItem.final_price = minPrice;
      selectedItem.added_at = new Date().toISOString();
      this.setState({ selectedItem: selectedItem })
      cart.addToCart(selectedItem).then(error => {
        if (!error) notify(`Your ${this.state.product.name} frame has been added to the cart!`, { type: 'success' })
      }).finally(() => {
        GTMEvents.addToCart(product, selectedItem);
        this.setState({ submitting: false })
        this.goToCartRef.current.focus()
      })
    } else {
      notify(`Please select a ${color ? 'size' : s} to continue`)
      this.setState({ submitting: false })
    }
  }

  refresh = () => {
    this.setState({ new: false, editing: null })
    this.loadProduct()
    refreshRoutes()
  }

  submitUpdate = async (update) => {
    update.series = this.state.product._id
    update.category = this.state.product.category._id
    await apiPost('products', update)
    this.refresh()
  }

  setOption = (obj) => {
    this.setState(obj, () => {
      this.sanityCheck(Object.keys(obj)[0]);

      if (obj.color) {
        this.updateQueryString();
      }

      if (obj.length && obj.width) {
        this.updateQueryString();
      }
    });
    // Object.keys(obj).forEach(key => {
    //   this.setState({[key]: obj[key]}, ()=>this.sanityCheck(key))
    // })
  }

  unpackSize = (string) => {
    const s = string.split('x')
    return { length: parseFloat(s[0]), width: parseFloat(s[1]) }
  }

  getActiveSize = () => {
    return getSizeLabel(this.state.length, this.state.width)
  }

  setSize = (string) => {
    const { length, width } = this.unpackSize(string)
    this.setOption({ length, width })
  }

  zoom = () => {
    if ($(window).width() > 767) {
      this.setState({ showGalleryModal: true });
    }
  }

  setShellFoam = (obj) => {
    let newState = Object.assign({}, obj)
    if (obj.shellOnly) newState.foamCore = false
    if (obj.foamCore) newState.shellOnly = false
    this.setOption(newState)
  }

  getSelect = (type) => {
    const values = {
      width: this.state.width,
      length: this.state.length
    }

    const value = values[type]
    const sort = (a, b) => a - b
    const filter = (x, findValid) => {
      const valid = this.state.valid[type + 's'].includes(x)
      return findValid ? !!x && valid : !!x && !valid
    }

    const invalidSizes = this.state[type + 's'].filter(x => filter(x, false)).sort(sort)
    const validSizes = this.state[type + 's'].filter(x => filter(x, true)).sort(sort)
    const sizes = validSizes.concat(invalidSizes)

    return <Select
      onChange={v => this.setOption({ [type]: v.value })}
      value={{ value: value, label: value + (value ? '"' : '') }}
      onMenuOpen={() => this.setOption({ [type]: '' })}
      options={
        sizes.map(c => {
          const active = this.state.valid[type + 's'].includes(c)
          return { value: c, label: <span className={'size-option ' + (active ? 'active' : '')}>{c + '"'}</span> }
        })
      } />
  }

  getSizeSelector1 = () => {
    const { valid, sizes, length, width, color, skus, selectedSkus } = this.state
    const checkSize = (s) => {
      s = this.unpackSize(s)
      return !color || !!skus.find(x => (x.color == color && s.length == x.length && s.width == x.width))
      // const isValid = (valid.lengths.includes(s.length) && valid.widths.includes(s.width))
      // return isValid
    }

    const invalidSizes = sizes.filter(x => !checkSize(x))
    const validSizes = sizes.filter(x => checkSize(x))
    const sortedSizes = validSizes.concat(invalidSizes)

    const selectedSize = `${length}x${width}`
    const label = (length || width) ? getSizeLabel(length, width) : <span className={'size-option'}>Select a size</span>

    return <Select
      aria-label='Select a size'
      onChange={v => this.setSize(v.value)}
      value={{ value: selectedSize, label }}
      onMenuOpen={() => this.setOption({ length: '', width: '' })}
      options={
        sortedSizes.map(c => {
          const active = checkSize(c)
          const us = this.unpackSize(c)
          const skus = selectedSkus.filter(x => x.length == us.length && x.width == us.width)
          const inventory = skus.length == 1 && isFas(skus[0]) ? `(${skus[0].inventory} in stock)` : ''
          return {
            value: c, label: <span className={'size-option ' + (active ? 'active' : '')}>
              {getSizeLabel(us.length, us.width)}
              {inventory && <span className='size-stock-label'>{inventory}</span>}
            </span>
          }
        })
      }
    />
  }

  getSizeSelector = () => {
    const { valid, sizes, length, width, color, skus, selectedSkus } = this.state
    const checkSize = (s) => {
      s = this.unpackSize(s)
      return !color || !!skus.find(x => (x.color == color && s.length == x.length && s.width == x.width))
      // const isValid = (valid.lengths.includes(s.length) && valid.widths.includes(s.width))
      // return isValid
    }

    const invalidSizes = sizes.filter(x => !checkSize(x))

    const validSizes = sizes.filter(x => checkSize(x))

    const sortedSizes = validSizes.concat(invalidSizes)

    const selectedSize = `${length}x${width}`

    const label = (length || width) ? getSizeLabel(length, width) : <span className={'size-option'}>Select a size</span>




    return <div role={'list'} aria-label='Select size' className='size-selector desktopview'>
      {sortedSizes.map(c => {
        const active = checkSize(c)
        const selected = (selectedSize == c ? 'active' : '')
        const us = this.unpackSize(c)
        const skus = selectedSkus.filter(x => x.length == us.length && x.width == us.width)
        const inventory = skus.length == 1 && isFas(skus[0]) ? `(${skus[0].inventory} in stock)` : ''
        const inventoryclass = (inventory) ? 'big' : 'small'
        return <div
          role={'listitem'}
          aria-label={`${us.length} by ${us.width}`}
          aria-selected={selectedSize == c}
          tabIndex={0}
          key={c}
          className={`size_option ${selected} ${inventoryclass} ${active ? 'show' : 'hide'}`}
          value={c}
          onClick={v => this.setSize(c)}
          onKeyDown={onEnterOrSpaceDownClick}
        >
          <div class="inner"> <div> {getSizeLabel(us.length, us.width)}</div>
            {inventory && <span className='size-stock-label'>{inventory}</span>}
          </div>
        </div>
      })}
    </div>
  }

  getField = (state, text, labeledBy) => {
    const props = {
      value: this.state[state],
      placeholder: state,
      onBlur: this.sanitizeLW
    }
    return <input aria-labelledby={labeledBy} onChange={(e, v) => this.setOption({ [state]: e.target.value })} {...props} />
  }

  customSize = () => {
    let options = { sizeType: 'custom' }
    if (!this.state.length) options.length = 4
    if (!this.state.width) options.width = 4
    this.setOption(options)
  }

  standardSize = () => {
    this.setOption({ length: '', width: '', sizeType: 'standard' })
  }
  moveMatWidth = () => {
    this.setState({ showShellOnlyModal: false });
  }

  startOver = () => {
    console.log("Click Start Over");
    this.setState({
      // start with empty product object so methods/props exist
      shellOnly: false,
      showShellOnlyModal: false,
    });
  }

  render() {
    //const money = (n) => n?n.toFixed(2):0;

    const money = (n) => {
      if (n) {
        //console.log("nnnnnnnnn", n);
        let t = (n * 1000).toFixed();
        let st = ((t % 100)) / 10;
        //console.log("sttttt", st);
        if (t % 10 == 5) {
          t = t / 1000 + 0.01;
        } else {
          t = t / 1000;
        }
        //console.log("ttttttt", t);
        return t.toFixed(2);
      }
    };

    const { product, length, width, color, skus, skusLoaded, minPrice, maxPrice,
      sizeType, shellOnly, qty, customPrices, submitting, selectedItem, validItem } = this.state
    const category = product.category
    const pageID = 'product-' + this.props.id
    const validConfig = (this.isValid() && !submitting && validItem) ? true : false
    const empty = skusLoaded && product.skus.length == 0
    const outOfStock = skusLoaded && product.skus.length > 0 && product.getSkus().length == 0
    let price = (isNaN(minPrice) || isNaN(maxPrice)) ? '' : `$${money(minPrice)} - $${money(maxPrice)}`
    if (minPrice == maxPrice) price = `$${money(minPrice)}`
    //console.log("Price123123", minPrice, price);
    const snipExists = name => {
      const s = getSnip(name, pageID)
      return !s || s == '<p></p>'
    }
    const hasFeatured = true || snipExists('list1') || snipExists('list2') || snipExists('list3')

    const defaultSnip = (v) => `<h5>${v}</h5><ul><li>lorem</li><li>ipsum</li><li>dolor</li></ul>`

    if (empty) return <Page id={pageID}>
      <div className='container' style={{ marginTop: '40px' }}>
        <Snip page='product-detail' name='empty-product' default='<h3>Sorry, it looks like this product is currently unavailable.</h3>' />
        <ProductEditor product={product} skus={skus} submitUpdate={this.submitUpdate} />
      </div>
    </Page>

    const thumbnailProps = (slide) => {
      return {
        toolPosition: 'left',
        className: 'thumbnail image',
        onClick: () => this.setState({ slide }),
        color,
        product
      }
    }

    const customTable = product.getPriceTable(selectedItem)

    const sorting = (product.color_sorting || []).filter(x => this.state.colors.includes(x))
    let colors = sorting.concat(this.state.colors.filter(x => !sorting.includes(x)))

    // GTM Product detail Tracking  Start

    // GTM Product detail Tracking  End

    return <Page id={pageID}>
      <ProductDetail>
        <div className='container'>
          <div className='row'>
            {category.name && <BreadCrumbs links={[{ link: '/' + category.url, label: category.name }, { label: product.name }]} />}
          </div>
          <div className='row'>
            <div className='col-md-6'>
              <h1 className='title h2'>{product.name}</h1>
              <div className='images row'>
                <div className='col-md-3 thumbnails'>
                  <ProductImage {...thumbnailProps(1)} type='preview' />
                  <ProductImage {...thumbnailProps(2)} type='corner' />
                  <ProductImage {...thumbnailProps(3)} type='profile' />
                </div>

                <div className='col-md-9' onClick={this.zoom}>
                  <Slideshow slide={this.state.slide}>
                    <ProductImage className='image' color={color} product={product} type='preview' />
                    <ProductImage className='image' color={color} product={product} type='corner' />
                    <ProductImage className='image' color={color} product={product} type='profile' />
                  </Slideshow>
                </div>

              </div>
              <AdvMuiModal open={this.state.showGalleryModal}
                onClose={() => this.setState({ showGalleryModal: false })}
                id="gallery-modal-id"
                maxWidth={false}
              >
                <div className='images row' style={{ width: 900, backgroundColor: 'white', padding: '44px', margin: 0 }}
                >
                  <div className='col-md-3 thumbnails'>
                    <ProductImage {...thumbnailProps(1)} type='preview' />
                    <ProductImage {...thumbnailProps(2)} type='corner' />
                    <ProductImage {...thumbnailProps(3)} type='profile' />
                  </div>
                  <div className='col-md-9'>
                    <Slideshow slide={this.state.slide}>
                      <ProductImage className='image' color={color} product={product} type='preview' />
                      <ProductImage className='image' color={color} product={product} type='corner' />
                      <ProductImage className='image' color={color} product={product} type='profile' />
                    </Slideshow>
                  </div>
                </div>
              </AdvMuiModal>
            </div>

            <div className='col-md-6 details'>
              {this.state.sizes.length > 1 && <div
                className='sizes'
                role='group'
                aria-label='Select Frame Size'
              >
                <a className='what-comes' onClick={() => this.details.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
                  <Snip component='span' default='More details...' name='product-more-details' page='global' />
                </a>
                <h5>Size <SizeInfo /></h5>
                {this.state.customAvailable && <div tabindex={0} aria-role='tablist' aria-label={'Select standard or custom size'} className='size-types'>
                  <button role='tab' aria-selected={sizeType === 'standard'} onClick={this.standardSize} className={sizeType == 'standard' ? 'active' : ''}>Standard</button>
                  <button role='tab' aria-selected={sizeType === 'custom'} onClick={this.customSize} className={sizeType == 'custom' ? 'active' : ''}>Custom</button>
                </div>}
                <Collapsible open={sizeType == 'standard'}>
                  <div className='size-selector mobile'>
                    {this.getSizeSelector1()}
                  </div>
                  {this.getSizeSelector()}
                  {/* { this.getSelect('length') }
                      <span className='fa fa-times' />
                      { this.getSelect('width') } */}

                </Collapsible>
                <Collapsible open={sizeType == 'custom'}>
                  <div className='custom-params'>
                    <div>
                      <label id='lengthField'>Length</label>
                      {this.getField('length', 'text', 'lengthField')}
                    </div>
                    <div>
                      <label id='widthField'>Width</label>
                      {this.getField('width', 'text', 'widthField')}
                    </div>
                    <div className='box-field'>
                      <label id={'shellOnly'}>Shell Only?</label>
                      <div className='check-box'>
                        <CheckBox
                          inputProps={{ 'aria-labelledby': 'shellOnly' }}
                          onChange={(e, v) => { this.setShellFoam({ shellOnly: v }); v === true ? this.setState({ showShellOnlyModal: true }) : ""; }}
                          checked={this.state.shellOnly}
                        />
                      </div>
                    </div>
                    <div className='box-field'>
                      <label id={'foamCore'}>Foam Core?</label>
                      <div className='check-box'>
                        <CheckBox
                          inputProps={{ 'aria-labelledby': 'foamCore' }}
                          onChange={(e, v) => this.setShellFoam({ foamCore: v })}
                          checked={this.state.foamCore}
                        />
                      </div>
                    </div>
                  </div>
                  <AdvMuiModal open={this.state.showShellOnlyModal}
                    onClose={() => this.setState({ showShellOnlyModal: false })}
                    maxWidth={false}
                  >
                    <Sect id="hidden-content" style={{ display: 'block' }}>
                      <div className="popupHead"> Attention: </div>
                      <Snip className='popupDesc' name='productsize-description' page='info-page' />
                      <div className="btnCenter">
                        <button onClick={this.moveMatWidth}>Click to Acknowledge and Continue</button>
                        <button onClick={this.startOver}>Start Over <span className='fa fa-refresh' /></button>
                      </div>
                    </Sect>
                  </AdvMuiModal>
                </Collapsible>
              </div>}

              <Collapsible open={sizeType == 'custom'}>
                {customTable && <div className='shipping'>
                  <Snip default='<p>Custom Pricing Info</p>' />
                  <table>
                    <tr>
                      <th>Qty Ordered</th> <th>Unit Discount</th> <th>Unit Price</th>
                    </tr>
                    {['tier0', 'tier1', 'tier2', 'tier3'].map(x => {
                      const ct = customTable[x]
                      const active = (qty >= ct.min && (!ct.max || qty < ct.max))
                      return <tr key={x} className={`${active ? 'active' : ''}`}>
                        <td>{ct.min}</td> <td>${money(customTable.base - ct.price)}</td> <td>${money(ct.price)}</td>
                      </tr>
                    })}
                  </table>
                </div>}
                {/* <div className='col-sm-12 shipping'>
                    <Snip component='h3' default='<h3>Shipping Information</h3>' page='shipping-info-modal' name='heading'/>
                    <Snip placeholder='Table description' page='shipping-info-modal' name='heading paragraph'/>
                    <Table page='shipping-info-modal' name='shipping-info' defaultOptions = {{rows: 6, cols: 3}} />
                  </div> */}
              </Collapsible>

              {!outOfStock && <div>
                {colors.length > 1 && <h5>Color</h5>}
                {colors.length > 1 && <div
                  className='color-selector'
                  role='list'
                  aria-label='Select Frame Color'
                >
                  {colors.map(c => {
                    const active = (color == c ? 'active' : '')
                    const disabled = (!this.state.valid.colors.includes(c) ? 'disabled' : '')
                    return <div
                      key={c}
                      role='listitem'
                      aria-label={helpers.getTitle(c)}
                      aria-selected={color == c}
                      tabIndex={0}
                      className={`option ${active} ${disabled}`}
                      onClick={() => this.setOption({ color: c })}
                      onKeyDown={onEnterOrSpaceDownClick}
                    >
                      <ProductImage product={product} color={c} toolPosition='top' type='color_preview' className='inner' />
                      {getGlobal('is_admin') && <div className='fas fa-fast-backward' onClick={() => product.setColorPriority(c)} />}
                      <div className='cross-bar' />
                      <div className='tool-tip'>{helpers.getTitle(c)}</div>
                    </div>
                  })}
                </div>}


                <div className='your-order'>
                  <h5>Your Order</h5>
                  <p>
                    {(selectedItem && !selectedItem.custom && selectedItem.display_name)
                      || `${this.getActiveSize()} ${product.name} ${color ? `in ${helpers.getTitle(color)}` : ''}`} {validConfig && <CheckMark />}
                  </p>
                  {/* {this.isFastShip() && <p>Fast Ship is available for this product!</p>} */}
                  <div className='price-row'>
                    <div className='qty'>
                      <label>Qty:</label>
                      <Qty onChange={qty => this.setOption({ qty })} value={this.state.qty} min={1} />
                    </div>
                    <h3 tabIndex={0} className='price col-sm-6 text-right'>{price}</h3>
                  </div>

                </div>

                {outOfStock && <div className='no-stock'>
                  <h5>{product.name} is currently out of stock</h5>
                  <p>Please check out our related products or try again later</p>
                </div>}


                <button
                  className={`btn btn-primary cart-btn ${validConfig ? 'valid' : ''}`}
                  onClick={this.submit}
                >
                  Add to Cart {submitting ? <Spinner /> : ''}
                </button>
                <NavLink
                  innerRef={this.goToCartRef}
                  className={`btn btn-primary cart-btn go-to-cart valid visible-on-focus`} to='/cart'
                >
                  Go to Cart
                </NavLink>
                {/* <div className='shipping'>
                    Shipping Info <ShippingInfo />
                  </div> */}
              </div>}



              {getGlobal('is_admin') && validConfig && <p style={{ marginTop: '20px', opacity: .5 }}>Selected Skus: [{[selectedItem.sku || '', selectedItem.fasSku || ''].filter(x => !!x).join(', ')}]</p>}
            </div>
          </div>

          <div className='desktop description col-sm-12'>
            {product.fasOnly && <Snip page='product-detail' name='fas-description' default='<p>Viewing fast ship products only</p>' />}
            <Snip name='product-description' lorem={42} />
          </div>
          <div className='desktop col-sm-12'>
            {(getGlobal('is_admin') || hasFeatured) && <div className='col-sm-12 row features' ref={d => this.details = d}>
              <Snip className='col-md-4' name='list1' placeholder={defaultSnip('Feature 1')} />
              <Snip className='col-md-4' name='list2' placeholder={defaultSnip('Feature 2')} />
              <Snip className='col-md-4' name='list3' placeholder={defaultSnip('Feature 3')} />
            </div>}

            <div className='related-products'>
              {(product.related || []).length > 0 && <Snip page='product-detail' name='related-header' component='h2' default='<h2>Related Products</h2>' />}
              <RelatedProducts product={product} />
            </div>

            <div className='related-products'>
              {(product.accessories || []).length > 0 && <Snip page='product-detail' name='accessories-header' component='h2' default='<h2>Accessories</h2>' />}
              <RelatedProducts product={product} category='Accessories' />
            </div>
          </div>

          <div className='mobile container mobile-details'>
            <h3 onClick={() => this.setState({ mobile_menu: this.state.mobile_menu == 'details' ? '' : 'details' })}>Details</h3>
            <Collapsible open={this.state.mobile_menu == 'details'}>
              <div className='description'>
                {product.fasOnly && <Snip page='product-detail' name='fas-description' default='<p>Viewing fast ship products only</p>' />}
                <Snip name='product-description' lorem={42} />
                <a className='' onClick={() => {
                  this.setState({ mobile_menu: 'what-comes' })
                  setTimeout(() => this.mobile_details.scrollIntoView({ behavior: 'smooth', block: 'start' }), 500)
                }}>
                  <Snip component='span' default='More details...' name='product-more-details' page='global' />
                </a>
              </div>
            </Collapsible>
            <h3 onClick={() => this.setState({ mobile_menu: this.state.mobile_menu == 'what-comes' ? '' : 'what-comes' })}>
              What comes with my frame?
            </h3>
            <Collapsible open={this.state.mobile_menu == 'what-comes'}>
              {(getGlobal('is_admin') || hasFeatured) && <div className='col-sm-12 row features' ref={d => this.mobile_details = d}>
                <Snip className='col-md-4' name='list1' placeholder={defaultSnip('Feature 1')} />
                <Snip className='col-md-4' name='list2' placeholder={defaultSnip('Feature 2')} />
                <Snip className='col-md-4' name='list3' placeholder={defaultSnip('Feature 3')} />
              </div>}
            </Collapsible>

            {(product.related || []).length > 0 && <div>
              <h3 onClick={() => this.setState({ mobile_menu: this.state.mobile_menu == 'related' ? '' : 'related' })}>
                Related Products
              </h3>
              <Collapsible open={this.state.mobile_menu == 'related'}>
                <div className='related-products'>
                  {(product.related || []).length > 0 && <Snip page='product-detail' name='related-header' component='h2' default='<h2>Related Products</h2>' />}
                  <RelatedProducts product={product} />
                </div>
              </Collapsible>
            </div>}

            {(product.accessories || []).length > 0 && <div>
              <h3 onClick={() => this.setState({ mobile_menu: this.state.mobile_menu == 'accessories' ? '' : 'accessories' })}>
                Accessories
              </h3>
              <Collapsible open={this.state.mobile_menu == 'accessories'}>
                <div className='related-products'>
                  {(product.accessories || []).length > 0 && <Snip page='product-detail' name='accessories-header' component='h2' default='<h2>Accessories</h2>' />}
                  <RelatedProducts product={product} category='Accessories' />
                </div>
              </Collapsible>
            </div>}
          </div>


        </div>
        <Loading active={!skusLoaded} />
        <ProductEditor product={product} skus={skus} submitUpdate={this.submitUpdate} />
      </ProductDetail>
    </Page>
  }
}
